<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title class="d-flex">
        <back-button color="grey" @click="dialog = false" />
        Termos e condições
      </v-card-title>
      <v-card-text class="py-5">
        <warning-section>
          <span>
            Os termos de uso e a política de privacidade do AppBio foram
            atualizados. Para continuar utilizando o aplicativo, é necessário
            que você leia e aceite os novos <strong> termos de uso</strong> e a
            nova
            <strong>política de privacidade</strong>
            do aplicativo.
          </span>
        </warning-section>
        <v-form ref="form" @submit="handleSubmit">
          <v-row class="terms">
            <a
              href="https://s3.amazonaws.com/files.prod.appbio/termos_e_condicoes_gerais_de_uso_e_ou_de_venda.pdf"
              target="_blank"
              >Ler Termos de Uso</a
            >
            <a
              href="https://s3.amazonaws.com/files.prod.appbio/politica_de_privacidade.pdf"
              target="_blank"
              >Ler Política de Privacidade</a
            >
          </v-row>

          <v-row style="margin-bottom: 20px; padding: 0 10px">
            <v-checkbox
              v-model="user.acceptedTerms"
              label="Li e aceito os termos de uso e a política de privacidade"
              hide-details
              :rules="[$rules.required]"
            />
          </v-row>
          <div class="d-flex justify-space-between mt-5">
            <v-btn @click="logout">
              <v-icon class="ml-1">arrow_back</v-icon>
              <span class="ml-1"> Sair</span>
            </v-btn>
            <v-btn
              class="primary"
              type="submit"
              :disabled="!user.acceptedTerms"
            >
              <v-icon class="ml-1">send</v-icon>
              <span class="ml-1"> Continuar</span>
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { acceptTerms } from "@/services/external/users-service";

export default {
  name: "DialogAcceptTerms",
  data: () => ({
    dialog: true,
    user: {
      acceptedTerms: false,
    },
  }),

  methods: {
    logout() {
      this.$store.dispatch("login/logout");
    },
    async handleSubmit(event) {
      event.preventDefault();
      if (!this.$refs.form.validate()) return;
      try {
        this.$store.dispatch("loading/openDialog");
        await acceptTerms();
        this.$store.dispatch("login/acceptTerms");
        this.dialog = false;
        this.$toasted.global.success({
          message: "Termos aceitos com sucesso! Você será redirecionado.",
        });
        this.$refs.form.reset();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style scoped>
.terms {
  margin-top: 20px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 10px;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 8px;
}
</style>
